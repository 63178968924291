.home-view .view-title {
    text-align: left;
}

.home-view .view-title > div {
    max-width: var(--max-width);
}

.home-view .view-title .content {
    max-width: 400px;
}

.home-view .view-title .description {
    font-size: 1.1rem;
    margin-top: 5px;
}

.home-view .view-title .title {
    text-align: inherit;
}

.home-view .view-title .logo {
    width: 500px;
}

@media only screen and (max-width: 1100px) {

    .home-view .view-title .logo {
        display: none;
    }

}